import React, { useState, useEffect, useMemo} from "react";
import { useNavigate , useLocation} from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import axios from "axios";
import config from "../config";
import { Toaster, toaster } from "./ui/toaster";
import {
  Box,
  VStack,
  Text,
  Input,
  Button,
  IconButton,
  Card,
  CardBody,
  Badge,
  Flex,
  Spinner,
  Separator,
} from "@chakra-ui/react";
import { diff_match_patch } from "diff-match-patch";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";
import { ProgressRoot, ProgressBar } from "./ui/progress";

const { API_BASE_URL } = config;
const dmp = new diff_match_patch();

const TrainingInterface = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const { category, problemSet } = location.state || {};
  const { user } = useAuth();
  const [sessionId, setSessionId] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [userAnswer, setUserAnswer] = useState("");
  const [submittedAnswer, setSubmittedAnswer] = useState("");
  const [showingModelAnswer, setShowingModelAnswer] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questionsAnswered, setQuestionsAnswered] = useState(0);
  const [batchSize] = useState(10); // Set batch size to 10
  const [isProcessing, setIsProcessing] = useState(false); // New state for overall processing

  // Initialize session
  useEffect(() => {
    const initSession = async () => {
      try {
        // Generate session ID first
        const newSessionId = crypto.randomUUID();
        const response = await axios.post(`${API_BASE_URL}/api/training/start`, {
            userId: user.id,
            sessionId: newSessionId,
          category: category,  // Add the category
          problemSet: problemSet  // Add the problem set
        });

        // Save session ID and question data after successful response
        setSessionId(newSessionId);
        setCurrentQuestion(response.data.question);
      } catch (error) {
        toaster.create({
          title: "Error starting session",
          description: error.response?.data?.detail || error.message,
          type: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    
    initSession();
  }, [user.id, category, problemSet]);

  useEffect(() => {
    if (!category || !problemSet) {
      toaster.create({
        title: "Missing Parameters",
        description: "Please select a category and problem set from the training dashboard",
        type: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate('/training');
      return;
    }
  }, [category, problemSet, navigate]);

  const handleShowAnswer = async () => {
    try {
      // Record "no attempt" in the backend
      await axios.post(`${API_BASE_URL}/api/training/record-no-attempt`, {
        sessionId,
        timestamp: new Date().toISOString(),
      });

      // Set feedback with only model answer
      setFeedback({
        assessment: "no attempt",
        model_answer: currentQuestion.model_answer,
      });
      setShowingModelAnswer(true);
      setQuestionsAnswered((prev) => prev + 1);
    } catch (error) {
      toaster.create({
        title: "Error recording response",
        description: error.response?.data?.detail || error.message,
        type: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const TextDiffViewer = ({ originalText, enhancedText }) => {
    const diffs = useMemo(() => {
      // Compute the diff
      const diff = dmp.diff_main(originalText, enhancedText);
      // Clean up the diff for better readability
      dmp.diff_cleanupSemantic(diff);
      return diff;
    }, [originalText, enhancedText]);

    return (
      <Box p={3} bg="gray.50" borderRadius="md" fontSize="md" lineHeight="tall">
        {diffs.map((diff, index) => {
          const [operation, text] = diff;

          if (operation === 0) {
            // No change
            return (
              <Text as="span" key={index}>
                {text}
              </Text>
            );
          } else if (operation === -1) {
            // Deletion
            return (
              <Text
                as="span"
                key={index}
                bg="red.100"
                textDecoration="line-through"
                color="red.800"
              >
                {text}
              </Text>
            );
          } else {
            // Addition
            return (
              <Text
                as="span"
                key={index}
                bg="green.100"
                color="green.800"
                fontWeight="medium"
              >
                {text}
              </Text>
            );
          }
        })}
      </Box>
    );
  };

  const getAssessmentColor = (assessment) => {
    switch (assessment) {
      case "correct":
        return "green";
      case "partially correct":
        return "yellow";
      case "incorrect":
        return "red";
      default:
        return "gray";
    }
  };

  const getAssessmentText = (assessment) => {
    switch (assessment) {
      case "correct":
        return "Correct!";
      case "partially correct":
        return "Partially Correct - Good Understanding";
      case "incorrect":
        return "Incorrect";
      default:
        return "Not Evaluated";
    }
  };

  // Handle text submission
  const handleSubmit = async () => {
    if (!userAnswer.trim() || !sessionId) {
      toaster.create({
        title: "Error",
        description: !sessionId
          ? "Session not initialized"
          : "Please enter an answer",
        type: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    setIsProcessing(true);

    try {
      const response = await axios.post(`${API_BASE_URL}/api/training/submit`, {
        sessionId: sessionId,
        answer: userAnswer,
      });

      setSubmittedAnswer(userAnswer);
      setFeedback(response.data.feedback);
      setQuestionsAnswered((prev) => prev + 1);
      setUserAnswer("");
    } catch (error) {
      toaster.create({
        title: "Error submitting answer",
        description: error.response?.data?.detail || error.message,
        type: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
      setIsProcessing(false);
    }
  };

  // Handle moving to next question
  const handleNext = async () => {
    if (!sessionId) {
      toaster.create({
        title: "Error",
        description: "Session not initialized",
        type: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      setFeedback(null);
      setShowingModelAnswer(false);

      const response = await axios.post(`${API_BASE_URL}/api/training/next`, {
        sessionId: sessionId,
      });

      // Check if training is completed
      if (response.data.completed) {
        toaster.create({
          title: "Training Completed!",
          description: "You have completed all questions.",
          type: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/dashboard");
        return;
      }

      // Update question immediately if we have it
      if (response.data.question) {
        setCurrentQuestion(response.data.question);
      }
    } catch (error) {
      toaster.create({
        title: "Error getting next question",
        description: error.response?.data?.detail || error.message,
        type: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Handle voice recording
  const toggleRecording = async () => {
    if (!sessionId) {
      toaster.create({
        title: "Error",
        description: "Session not initialized",
        type: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (isRecording) {
      // Stop recording
      if (audioRecorder) {
        audioRecorder.stop();
        // Add these lines to clean up the stream
        const tracks = audioRecorder.stream.getTracks();
        tracks.forEach((track) => track.stop());
        setAudioRecorder(null); // Clear the recorder
        setIsRecording(false);
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);
        setAudioRecorder(recorder);

        recorder.ondataavailable = async (e) => {
          const formData = new FormData();
          formData.append("audio", new Blob([e.data], { type: "audio/webm" }));
          formData.append("sessionId", sessionId);
          formData.append("timestamp", new Date().toISOString());

          setIsProcessing(true);
          try {
            const response = await axios.post(
              `${API_BASE_URL}/api/training/submit-voice`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            setSubmittedAnswer(
              response.data.feedback.student_answer || response.data.text
            );
            setFeedback(response.data.feedback);
            setQuestionsAnswered((prev) => prev + 1);
          } catch (error) {
            toaster.create({
              title: "Error submitting voice answer",
              description: error.response?.data?.detail || error.message,
              type: "error",
              duration: 5000,
              isClosable: true,
            });
          } finally {
            // Add cleanup here as well
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
            setAudioRecorder(null);
            setIsProcessing(false);
          }
        };

        recorder.start();
        setIsRecording(true);
      } catch (error) {
        toaster.create({
          title: "Error accessing microphone",
          description: error.message,
          type: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Colors and styles
  const bgGradient = "linear(to-r, teal.50, blue.50)";
  const cardBg = "white";
  const inputBg = "gray.100";

  return (
    <Box minH="100vh" w="100%" bgGradient={bgGradient} py={10} px={4}>
      <Toaster />
      <Box
        p={6}
        maxW="800px"
        mx="auto"
        bg={cardBg}
        boxShadow="xl"
        borderRadius="xl"
        transition="all 0.3s ease-in-out"
      >
        {/* Add this section to show selected parameters */}
        <VStack spacing={2} mb={6} align="start">
          <Text fontSize="lg" fontWeight="bold" color="blue.600">
            Selected Category: {category}
          </Text>
          <Text fontSize="lg" fontWeight="bold" color="blue.600">
            Problem Set: {problemSet}
          </Text>
          <Separator />
        </VStack>

        {/* Rest of your existing UI components */}
        <ProgressRoot
          colorPalette="blue"
          mb={6}
          size="sm"
          value={(questionsAnswered / batchSize) * 100}
        >
          <ProgressBar />
        </ProgressRoot>
        <Text textAlign="right" mb={2} fontWeight="medium" color="gray.600">
          Question {questionsAnswered + 1}/{batchSize}
        </Text>

        {/* Question Card */}
        <Card mb={4} boxShadow="md" borderRadius="lg">
          <CardBody>
            <Text fontSize="xl" fontWeight="bold" color="gray.700">
              {currentQuestion?.text || "Loading..."}
            </Text>
          </CardBody>
        </Card>

        {/* Processing Indicator */}
        {isProcessing && (
          <Flex justify="center" my={4}>
            <VStack>
              <Spinner borderWidth="4px" colorPalette="blue.500" size="xl" />
              <Text color="gray.600" fontSize="sm">
                Evaluating your answer...
              </Text>
            </VStack>
          </Flex>
        )}

        {!feedback && !isProcessing && (
          <VStack spacing={4}>
            <Flex w="100%">
              <Input
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="Type your answer..."
                mr={2}
                bg={inputBg}
                isDisabled={isRecording}
                _focus={{
                  borderColor: "teal.400",
                  boxShadow: "0 0 0 1px teal.400",
                }}
                transition="all 0.2s"
              />
              <IconButton
                icon={isRecording ? <FaMicrophoneSlash /> : <FaMicrophone />}
                colorScheme={isRecording ? "red" : "blue"}
                onClick={toggleRecording}
                aria-label="Voice input"
                _hover={{ transform: "scale(1.05)", boxShadow: "md" }}
                transition="all 0.2s"
              />
            </Flex>
            <Flex w="100%" gap={4}>
              <Button
                colorScheme="blue"
                isLoading={isSubmitting}
                onClick={handleSubmit}
                flex="1"
                isDisabled={isRecording}
                _hover={{ bg: "blue.600", boxShadow: "md" }}
                transition="all 0.2s"
              >
                Submit Answer
              </Button>
              <Button
                colorScheme="gray"
                onClick={handleShowAnswer}
                flex="1"
                isDisabled={isRecording}
                _hover={{ bg: "gray.300", boxShadow: "md" }}
                transition="all 0.2s"
              >
                Show Answer
              </Button>
            </Flex>
          </VStack>
        )}

        {/* Feedback Section */}
        {feedback && (
          <VStack spacing={4} align="stretch" mt={6}>
            <Card boxShadow="md" borderRadius="lg">
              <CardBody>
                <VStack align="stretch" spacing={4}>
                  {!showingModelAnswer && (
                    <>
                      <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        mb={2}
                      >
                        <Badge
                          variant="solid"
                          colorScheme={getAssessmentColor(feedback.assessment)}
                          fontSize="md"
                          px={4}
                          py={2}
                          borderRadius="full"
                          mb={2}
                        >
                          {getAssessmentText(feedback.assessment)}
                        </Badge>

                        {/* Add console.log for debugging */}
                        {console.log("Feedback object:", feedback)}

                        {(feedback.reason || feedback.evaluation_reason) &&
                          feedback.assessment !== "correct" && (
                            <Text
                              fontSize="sm"
                              color="gray.700"
                              textAlign="center"
                              fontStyle="italic"
                              mt={2}
                              maxW="80%"
                            >
                              {feedback.reason || feedback.evaluation_reason}
                            </Text>
                          )}
                      </Flex>

                      {/* Your Answer */}
                      <Box>
                        <Text fontWeight="medium" color="gray.600" mb={1}>
                          Your Answer:
                        </Text>
                        <Text p={3} bg="gray.50" borderRadius="md">
                          {submittedAnswer}
                        </Text>
                      </Box>

                      <Separator />

                      {/* Enhanced Answer - only show for correct or partially correct */}
                      {(feedback.assessment === "correct" ||
                        feedback.assessment === "partially correct") &&
                        feedback.enhanced_answer && (
                          <>
                            <Box>
                              <Text fontWeight="medium" color="gray.600" mb={1}>
                                Enhanced Version of Your Answer:
                              </Text>
                              <TextDiffViewer
                                originalText={submittedAnswer}
                                enhancedText={feedback.enhanced_answer}
                              />
                            </Box>
                            <Separator />
                          </>
                        )}

                      {/* Detailed Feedback */}
                      {feedback.detailed_feedback && (
                        <>
                          <Box>
                            <Text fontWeight="medium" color="gray.600" mb={1}>
                              Feedback:
                            </Text>
                            <Text p={3} bg="blue.50" borderRadius="md">
                              {feedback.detailed_feedback}
                            </Text>
                          </Box>

                          <Separator />
                        </>
                      )}
                    </>
                  )}

                  {/* Model Answer (shown in both cases) */}
                  <Box>
                    <Text fontWeight="medium" color="gray.600" mb={1}>
                      Model Answer:
                    </Text>
                    <Text p={3} bg="green.50" borderRadius="md">
                      {showingModelAnswer
                        ? currentQuestion.model_answer
                        : feedback.model_answer}
                    </Text>
                  </Box>

                  <Button
                    colorScheme="green"
                    onClick={handleNext}
                    size="lg"
                    mt={2}
                    _hover={{ bg: "green.600", boxShadow: "md" }}
                    transition="all 0.2s"
                  >
                    Next Question
                  </Button>
                </VStack>
              </CardBody>
            </Card>
          </VStack>
        )}
      </Box>
    </Box>
  );
};

export default TrainingInterface;
