import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
} from "@chakra-ui/react";
import {
  mockCategories,
  mockProblemSets,
} from "../../constants/TrainingDashboardMocks";
import { Toaster, toaster } from "../ui/toaster";
import { TrainingCategoryCard } from "./TrainingCategoryCard";
import { TrainingProblemSetCard } from "./TrainingProblemSetCard";
import { Tooltip } from "../ui/tooltip";

const TrainingDashboard = () => {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSet, setSelectedSet] = useState("");

  const handleStartTraining = () => {
    if (!selectedCategory || !selectedSet) {
      toaster.create({
        title: "Selection Required",
        description: "Please select both a category and a problem set",
        type: "warning",
        duration: 3000,
      });
      return;
    }

  // Navigate to the training session with selected options
  navigate('/training/session', {
      state: {
        category: selectedCategory,
        problemSet: selectedSet
      }
    });
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Toaster />
      <Box px={6}>
        <Flex align="center" justify="space-between" mb={4}>
          <div>
            <Heading size="4xl" mb={2} color="blue.500">
              Training Mode
            </Heading>
            <Text fontSize="md" color="fg.muted">
              Enhance your knowledge through focused practice sessions
            </Text>
          </div>
          <Tooltip
            content="Please choose at least one category and one problem set."
            disabled={selectedCategory && selectedSet}
          >
            <Button
              colorPalette="blue"
              size="lg"
              onClick={handleStartTraining}
              disabled={!selectedCategory || !selectedSet}
            >
              Start Training Session
            </Button>
          </Tooltip>
        </Flex>
      </Box>
      <Box px={6} my={8}>
        <Heading size="xl" mb={2}>
          Select Category
        </Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(310px, 1fr))" gap={6}>
          {mockCategories.map((category, index) => (
            <TrainingCategoryCard
              key={index}
              category={category}
              onSelect={() => setSelectedCategory(category.id)}
              isCardSelected={selectedCategory === category.id}
            />
          ))}
        </Grid>
      </Box>
      <Box px={6} my={8}>
        <Heading Heading size="xl" mb={2}>
          Select Problem Set
        </Heading>
        <Grid templateColumns="repeat(auto-fill, minmax(280px, 1fr))" gap={6}>
          {mockProblemSets.map((set, index) => (
            <TrainingProblemSetCard
              key={index}
              problemSet={set}
              isCardSelected={selectedSet === set.id}
              onSelect={() => setSelectedSet(set.id)}
            />
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default TrainingDashboard;
