
'use client'

import { Tabs as ChakraTabs } from '@chakra-ui/react'
import * as React from 'react'

export const Tabs = React.forwardRef(function Tabs(props, ref) {
  return <ChakraTabs.Root ref={ref} {...props} />
})

export const TabsList = React.forwardRef(function TabsList(props, ref) {
  return <ChakraTabs.List ref={ref} {...props} />
})

export const TabsTrigger = React.forwardRef(function TabsTrigger(props, ref) {
  return <ChakraTabs.Trigger ref={ref} {...props} />
})

export const TabsContent = React.forwardRef(function TabsContent(props, ref) {
  return <ChakraTabs.Content ref={ref} {...props} />
})