import React from "react";
import { Box, Spinner } from "@chakra-ui/react";
import { Navigate, Route, Routes } from "react-router-dom";
import ConfirmSignUp from "./components/ConfirmSignUp";
import Dashboard from "./components/Dashboard";
import ExamModeHome from "./components/ExamModeHome";
import PrivateRoute from "./components/PrivateRoute";
import Profile from "./components/Profile";
import ScenarioBuilder from "./components/ScenarioManagement/ScenarioBuilder";
import ScenarioReader from "./components/ScenarioManagement/ScenarioReader";
import ScenarioSelection from "./components/Scenarios/ScenarioSelection";
import SignUp from "./components/SignUp";
import SimulationInterface from "./components/SimulationInterface/SimulationInterface";
import SimulationResult from "./components/SimulationResult/SimulationResult";
import TrainingDashboard from "./components/TrainingDashboard";
import TrainingInterface from "./components/TrainingInterface";
import StudyPlanWrapper from "./components/StudyPlan/StudyPlanWrapper";
import StudyHistory from "./components/StudyHistory"

import { useAuth } from "./contexts/AuthContext";
import { SimulationModeProvider } from "./contexts/SimulationModeContext";
import { Login } from "./components/Login";

// Create a wrapper component for simulation-related routes
const SimulationWrapper = ({ children }) => (
  <SimulationModeProvider>{children}</SimulationModeProvider>
);

export function AppRoutes() {
  const { isAuthenticated, isLoading, checkAuthStatus } = useAuth();

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />
        }
      />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/confirm-signup" element={<ConfirmSignUp />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route
        path="/study-plan/:planId"
        element={
          <PrivateRoute>
            <StudyPlanWrapper />
          </PrivateRoute>
        }
      />

      <Route
        path="/study-plans"
        element={
          <PrivateRoute>
            <Navigate to="/dashboard" replace />
          </PrivateRoute>
        }
      />
      <Route
        path="/training"
        element={
          <PrivateRoute>
            <TrainingDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/training/session"
        element={
          <PrivateRoute>
            <TrainingInterface />
          </PrivateRoute>
        }
      />
      

      {/* Wrap simulation-related routes with SimulationModeProvider */}
      <Route
        path="/scenarios"
        element={
          <PrivateRoute>
            <SimulationWrapper>
              <ScenarioSelection />
            </SimulationWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/study-history"
        element={
          <PrivateRoute>
            <SimulationWrapper>
              <StudyHistory />
            </SimulationWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path="/scenario_edit"
        element={
          <PrivateRoute>
            <ScenarioReader />
          </PrivateRoute>
        }
      />

      <Route
        path="/simulation/:scenarioId"
        element={
          <PrivateRoute>
            <SimulationWrapper>
              <SimulationInterface />
            </SimulationWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/simulation-result/:sessionId"
        element={
          <PrivateRoute>
            <SimulationWrapper>
              <SimulationResult />
            </SimulationWrapper>
          </PrivateRoute>
        }
      />

      <Route
        path="/scenario-builder/:scenarioName"
        element={
          <PrivateRoute>
            <ScenarioBuilder />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />

      <Route
        path="/exam-mode"
        element={
          <PrivateRoute>
            <SimulationWrapper>
              <ExamModeHome />
            </SimulationWrapper>
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
