import { Toaster, toaster } from "../ui/toaster";
import {
  Alert,
  Badge,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import { StudyPlanContainer } from "../StudyPlan/StudyPlanContainer";
import ScenarioList from "./ScenarioList";
import DashboardProfile from "./DashboardProfile";

export default function Dashboard() {
  const navigate = useNavigate();
  const { user, isLoading: authLoading } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleModeSelection = (mode) => {
    console.log(user?.["user_tier"]);
    if (mode.isPremium && user?.["user_tier"] !== "beta") {
      toaster.create({
        title: "Premium Feature",
        description: "Please upgrade to access this feature",
        type: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Navigate based on mode
    switch (mode.title) {
      case "Exam Mode":
        navigate("/exam-mode", {
          state: { userTier: user?.attributes?.["custom:user_tier"] },
        });
        break;
      case "Training Mode":
        navigate("/training");
        break;
      case "Study Resources":
        navigate("/resources");
        break;
      default:
        navigate("/dashboard");
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchDashboardData = async () => {
      if (!user?.sub) return;

      try {
        const response = await fetch(
          `${config.API_BASE_URL}/api/dashboard_data/${user.sub}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (isMounted) {
          setDashboardData(data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        if (isMounted) {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    fetchDashboardData();
    return () => {
      isMounted = false;
    };
  }, [user?.sub]);

  if (authLoading || loading) {
    return (
      <Container maxW="container.xl" py={6}>
        <Flex justify="center" align="center" minH="60vh">
          <Spinner size="xl" colorPalette="blue.500" borderWidth="4px" />
        </Flex>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxW="container.xl" py={6}>
        <Alert
          status="error"
          title={`Error loading dashboard data: ${error}`}
        ></Alert>
      </Container>
    );
  }

  if (!user || !dashboardData) {
    return (
      <Container maxW="container.xl" py={6}>
        <Alert status="warning" title="Unable to load user data"></Alert>
      </Container>
    );
  }

  // Renaming keys from the API response to match local variable names
  const {
    available_scenarios: scenarios,
    user_stats: userStats,
    recent_activity: recentActivity,
    training_stats: trainingStats,
  } = dashboardData;

  // Training mode cards configuration
  const trainingModes = [
    {
      title: "Exam Mode",
      description: "OSCE Style Assessment",
      buttonText: "Upgrade to Access",
      bgColor: "purple.500",
      isPremium: true,
    },
    {
      title: "Training Mode",
      description: "Practice Communication",
      buttonText: "Start Training",
      bgColor: "teal.500",
      isPremium: false,
    },
    {
      title: "Study Resources",
      description: "Communication Guidelines",
      buttonText: "Upgrade to Access",
      bgColor: "blue.500",
      isPremium: true,
    },
  ];

  return (
    <Container maxW="container.xl" py={6}>
      <Toaster />
      <Grid templateColumns="3fr 1fr" gap={8}>
        {/* Left Side - Main Content */}
        <VStack align="stretch" spacing={6}>
          {/* Training Mode Cards */}
          <Grid templateColumns="repeat(3, 1fr)" gap={4}>
            {trainingModes.map((mode, index) => (
              <Box
                key={index}
                bg={mode.bgColor}
                p={4}
                borderRadius="lg"
                color="white"
                position="relative"
                _hover={{ transform: "translateY(-2px)", shadow: "lg" }}
                transition="all 0.2s"
                cursor={mode.isPremium ? "not-allowed" : "pointer"}
                onClick={() => handleModeSelection(mode)}
              >
                {mode.isPremium && (
                  <Badge
                    position="absolute"
                    top={2}
                    right={2}
                    colorPalette="purple"
                    variant="solid"
                  >
                    Coming Soon...
                  </Badge>
                )}
                <Text fontSize="xl" fontWeight="bold" mb={1}>
                  {mode.title}
                </Text>
                <Text fontSize="sm" mb={4} opacity={0.9}>
                  {mode.description}
                </Text>
                <Button
                  size="sm"
                  bg="whiteAlpha.600"
                  variant="solid"
                  rightIcon={mode.isPremium ? <FaLock /> : null}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the box click handler from firing
                    handleModeSelection(mode);
                  }}
                >
                  {mode.buttonText}
                </Button>
              </Box>
            ))}
          </Grid>
          <Box>
            <StudyPlanContainer />
          </Box>

          {/* Scenario List */}
          <ScenarioList scenarios={scenarios || []} />
        </VStack>

        {/* Right Side - Profile */}
        <DashboardProfile 
          userStats={userStats}
          trainingStats={trainingStats}
          recentActivity={recentActivity}
        />
      </Grid>
    </Container>
  );
}
