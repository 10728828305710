import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { VscDebugRestart } from 'react-icons/vsc';
import {
  DialogRoot,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogBackdrop
} from '../ui/dialog';
import { Button } from '../ui/button';
import { useAnomalySimulationEnding } from '../../api/scenarios';

export const RestartSimulationDialog = ({ handleRestart }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleAnomalyEnding = useAnomalySimulationEnding();

  const handleRestartSimulation = () => {
    handleAnomalyEnding();
    handleRestart();
    setIsOpen(false);
  };

  return (
    <DialogRoot 
      open={isOpen} 
      onOpenChange={setIsOpen}
    >
      <DialogTrigger asChild>
        <Button colorPalette="red" className="w-full flex items-center gap-2">
          <VscDebugRestart />
          <span>Restart</span>
        </Button>
      </DialogTrigger>
      
      <DialogBackdrop />
      
      <DialogContent 
        className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md rounded-lg bg-white p-6"
      >
        <DialogHeader className="text-lg font-bold mb-4">
          Restart Simulation
        </DialogHeader>
        
        <DialogBody className="mb-6">
          Are you sure you want to restart the simulation? This will end the current session.
        </DialogBody>
        
        <DialogFooter className="flex justify-end gap-3">
          <Button 
            onClick={() => setIsOpen(false)}
            className="bg-gray-100 hover:bg-gray-200"
          >
            Cancel
          </Button>
          <Button 
            colorPalette="red" 
            onClick={handleRestartSimulation}
          >
            Restart
          </Button>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

RestartSimulationDialog.propTypes = {
  handleRestart: PropTypes.func.isRequired
};

export default RestartSimulationDialog;