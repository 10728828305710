import {
  Box,
  Icon,
  IconButton,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { BsPrescription } from "react-icons/bs";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "../ui/tabs";

export const SimulationInterfaceSideDrawer = ({ sidebarContent }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <IconButton
        colorScheme="blue"
        top={"40%"}
        left={"20px"}
        transform={isDrawerOpen ? "rotate(180deg)" : ""}
        rounded="full"
        size="xl"
        variant="solid"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <IoIosArrowBack />
      </IconButton>
      <Box
        borderLeftWidth="1px"
        paddingTop={5}
        paddingLeft={isDrawerOpen ? 5 : "0px"}
        width={isDrawerOpen ? "40%" : "65px"}
        transition="all 0.3s ease"
      >
        <Tabs
          defaultValue="resource"
          orientation={isDrawerOpen ? "horizontal" : "vertical"}
          variant="line"
          size="lg"
        >
          <TabsList>
            <TabsTrigger value="resource" marginY={2}>
              <Icon size="2xl" mr={2}>
                <HiOutlineDocumentText />
              </Icon>
              Resource
            </TabsTrigger>
            <TabsTrigger value="prescription" marginY={2}>
              <Icon size="2xl" mr={2}>
                <BsPrescription />
              </Icon>
              Prescription
            </TabsTrigger>
          </TabsList>

          {/* Doc Resource Tab */}
          <TabsContent value="resource" height="100vh" minWidth={"70%"}>
            <VStack align="stretch" spacing={4} height="100%">
              <Text fontSize="lg" fontWeight="bold">
                Medication Reference
              </Text>
              <Box p={4} bg="gray.50" borderRadius="md" overflowY="scroll">
                <ReactMarkdown
                  components={{
                    // Custom styling for markdown elements
                    h1: ({ node, ...props }) => (
                      <Text fontSize="2xl" fontWeight="bold" mb={4} {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                      <Text fontSize="xl" fontWeight="bold" mb={3} {...props} />
                    ),
                    h3: ({ node, ...props }) => (
                      <Text fontSize="lg" fontWeight="bold" mb={2} {...props} />
                    ),
                    p: ({ node, ...props }) => (
                      <Text mb={3} lineHeight="tall" {...props} />
                    ),
                    ul: ({ node, ...props }) => (
                      <List.Root styleType="disc" ml={4} mb={3} spacing={2} {...props} />
                    ),
                    ol: ({ node, ...props }) => (
                      <List.Root as="ol" styleType="decimal" ml={4} mb={3} spacing={2} {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <List.Item pl={2} mb={1} lineHeight="tall">
                        {props.children}
                      </List.Item>
                    ),
                  }}
                >
                  {sidebarContent?.resource ||
                    "No medication information available."}
                </ReactMarkdown>
              </Box>
            </VStack>
          </TabsContent>

          {/* Prescription Tab */}
          <TabsContent value="prescription" minWidth={"70%"}>
            <VStack align="stretch" spacing={4}>
              <Text fontSize="lg" fontWeight="bold">
                Prescription Guidelines
              </Text>
              <Box p={4} bg="gray.50" borderRadius="md" overflowY="auto">
                <ReactMarkdown
                  components={{
                    ul: ({ node, ...props }) => (
                      <List styleType="disc" ml={4} mb={3} spacing={1} {...props} />
                    ),
                    ol: ({ node, ...props }) => (
                      <List as="ol" styleType="decimal" ml={4} mb={3} spacing={1} {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <ListItem pl={2} mb={1} lineHeight="tall">
                        {props.children}
                      </ListItem>
                    ),
                  }}
                >
                  {sidebarContent?.prescription ||
                    "No prescription guidelines available."}
                </ReactMarkdown>
              </Box>
            </VStack>
          </TabsContent>
        </Tabs>
      </Box>
    </>
  );
};

SimulationInterfaceSideDrawer.propTypes = {
  sidebarContent: PropTypes.object.isRequired,
};
